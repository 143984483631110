<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <b-col md="12" class="text-left mt-1">
          <b-row>
            <b-col md="12"><h4>Content Data</h4></b-col>
            <b-col md="12">
              <div>
                <!-- types -->
                <app-collapse accordion :type="collapseType">
                  <app-collapse-item title="Header">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="4">
                            <b-img
                              rounded
                              :src="model.header.image"
                              alt="Rounded image"
                              class="d-inline-block mr-1 mb-1"
                              width="100px"
                            />
                          </b-col>

                          <b-col md="4">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.header.title }}</p>
                          </b-col>

                          <b-col md="4">
                            <p class="mb-0"><b>Subtitle</b></p>
                            <p>{{ model.header.subtitle }}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Process">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="12" class="mb-2">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.process.title }}</p>
                          </b-col>

                          <b-col md="12">
                            <b-row
                              v-for="(i, index) in model.process.steps"
                              :key="index"
                            >
                              <b-col md="3">
                                <b-img
                                  rounded
                                  :src="i.image"
                                  alt="Rounded image"
                                  class="d-inline-block mr-1 mb-1"
                                  width="100px"
                                />
                              </b-col>
                              <b-col md="3">
                                <p class="mb-0"><b>No.</b></p>
                                <p>{{ i.no }}</p>
                              </b-col>
                              <b-col md="3">
                                <p class="mb-0"><b>Title</b></p>
                                <p>{{ i.title }}</p>
                              </b-col>
                              <b-col md="3">
                                <p class="mb-0"><b>Description</b></p>
                                <p>{{ i.description }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Sample Banner">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="4">
                            <b-img
                              rounded
                              :src="model.downloadSampleBanner.image"
                              alt="Rounded image"
                              class="d-inline-block mr-1 mb-1"
                              width="100px"
                            />
                          </b-col>

                          <b-col md="4">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.downloadSampleBanner.title }}</p>
                          </b-col>

                          <b-col md="4">
                            <p class="mb-0"><b>Description</b></p>
                            <p>{{ model.downloadSampleBanner.description }}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Benefit">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="6">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.benefit.title }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Subtitle</b></p>
                            <p>{{ model.benefit.subtitle }}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Feature">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="12">
                            <b-row
                              v-for="(i, index) in model.features"
                              :key="index"
                            >
                              <b-col md="4">
                                <b-img
                                  rounded
                                  :src="i.logo"
                                  alt="Rounded image"
                                  class="d-inline-block mr-1 mb-1"
                                  width="100px"
                                />
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Title</b></p>
                                <p>{{ i.title }}</p>
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Subitle</b></p>
                                <p>{{ i.subtitle }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="FAQ">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="12">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.faq.title }}</p>
                          </b-col>

                          <b-col md="12">
                            <b-row
                              v-for="(i, index) in model.faq.faqs"
                              :key="index"
                            >
                              <b-col md="6">
                                <p class="mb-0"><b>Question</b></p>
                                <p>{{ i.question }}</p>
                              </b-col>
                              <b-col md="6">
                                <p class="mb-0"><b>Answer</b></p>
                                <p>{{ i.answer }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Promo Banner">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="4">
                            <b-img
                              rounded
                              :src="model.promoBanner.image"
                              alt="Rounded image"
                              class="d-inline-block mr-1 mb-1"
                              width="100px"
                            />
                          </b-col>

                          <b-col md="4">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.promoBanner.title }}</p>
                          </b-col>

                          <b-col md="4">
                            <p class="mb-0"><b>Description</b></p>
                            <p>{{ model.promoBanner.description }}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'content-contribute' }"
      >
        Back
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BModal,
  VBModal,
  BFormRadio,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    vSelect,
    BTab,
    BTabs,
    BFormRadio,
    BModal,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    "b-modal": VBModal,
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      collapseType: "margin",
      config: {
        api: "content/contribute",
      },
      model: {},
    };
  },
  beforeMount() {
    let _ = this;
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data;
      });
    },
  },
};
</script>
